<template>
  <div class="invoicing">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }"
        ><i class="el-icon-s-home"></i
      ></el-breadcrumb-item>

      <el-breadcrumb-item
        v-if="$route.query.shopname"
        :to="{ path: `/detail?id=${$route.query.shopid}` }"
        >{{ $route.query.shopname }}</el-breadcrumb-item
      >
      <el-breadcrumb-item v-else :to="{ path: '/shoppingCart' }"
        >购物车</el-breadcrumb-item
      >
      <el-breadcrumb-item>结账</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="distribution">
      <div class="add-address">
        <div>配送地址</div>
        <div @click="dialogFormVisible2 = true">添加新地址</div>
      </div>
      <div class="current-address">
        <div
          style="color: #ffda00; margin-right: 10px"
          class="el-icon-location"
        ></div>
        <div
          @mouseover="currenEdit = true"
          @mouseleave="currenEdit = false"
          class="edit"
        >
          <div>
            {{ currentAddress.contract_name }}({{
              currentAddress.contract_phone
            }}) {{ currentAddress.province }} {{ currentAddress.city }}
            {{ currentAddress.county }}{{ currentAddress.address }}
          </div>
          <div v-show="currenEdit">编辑</div>
        </div>
      </div>
      <div v-show="showAddress">
        <div
          @click="addressClick(index)"
          @mouseover="moreAddressList[index].checked = true"
          @mouseleave="moreAddressList[index].checked = false"
          v-for="(item, index) in moreAddressList"
          :key="index"
          class="current-address2"
        >
          <div class="edit">
            <div>
              {{ item.contract_name }}({{ item.contract_phone }})
              {{ item.province }} {{ item.city }} {{ item.county
              }}{{ item.address }}
            </div>
            <div @click.stop="edit(item)" v-show="item.checked">编辑</div>
          </div>
        </div>
      </div>
      <div @click="showAddress = !showAddress" class="more-address">
        所有地址
        <span
          :style="{
            transform: showAddress ? 'rotateZ(270deg)' : 'rotateZ(90deg)',
          }"
          class="el-icon-d-arrow-right"
        ></span>
      </div>
    </div>
    <div class="top-content">
      <!-- <div class="content">
        <div class="top">支付方式</div>
        <el-radio>银行转账</el-radio>
      </div>
      <div class="content">
        <div class="top">配送方式</div>
        <div class="bottom">购物车商品不需要配送</div>
      </div> -->
    </div>
    <div class="cart-content">
      <div class="top">确认订单信息</div>
      <!-- <el-table>
        <el-table-column label="图片"></el-table-column>
        <el-table-column label="商品名称"></el-table-column>
        <el-table-column label="型号"></el-table-column>
        <el-table-column label="数量"></el-table-column>
        <el-table-column label="单价"></el-table-column>
        <el-table-column label="合计"></el-table-column>
      </el-table> -->

      <div class="cart-table">
        <div class="cart-table-header">
          <div>图片</div>
          <div>商品名称</div>
          <div>型号</div>
          <div>单价</div>
          <div>数量</div>
          <div>合计</div>
        </div>
        <div class="cart-table-list">
          <div class="cart-table-content">
            <div>
              <img :src="detail.img" alt="" />
            </div>
            <div>{{ detail.name }}</div>
            <div>Product 18</div>
            <div>￥{{ detail.price }}</div>
            <div>
              {{ $route.query.shopnum }}
            </div>
            <div>￥{{ detail.price * $route.query.shopnum }}</div>
          </div>
        </div>
        <div class="table-price">
          <div>商品总额:</div>
          <!-- <div>￥{{ total.toFixed(2) }}</div> -->
          <div>￥{{ (detail.price * $route.query.shopnum).toFixed(2) }}</div>
        </div>
        <div class="table-price">
          <div>免费配送:</div>
          <div>￥0.00</div>
        </div>
        <div class="table-price" style="padding-bottom: 40px">
          <div>订单总额:</div>
          <!-- <div>￥{{ total.toFixed(2) }}</div> -->
          <div>￥{{ (detail.price * $route.query.shopnum).toFixed(2) }}</div>
        </div>
      </div>
    </div>
    <div class="message-content">
      <div class="top">订单留言</div>
      <el-input type="textarea" :row="5"></el-input>
    </div>
    <div class="bottom">
      <el-checkbox>我已阅读并同意<a href="#">使用条款</a></el-checkbox>
      <el-button @click="submitOrder">确认订单</el-button>
    </div>

    <el-dialog title="修改地址" :visible.sync="dialogFormVisible">
      <el-form
        :model="form"
        style="
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        "
      >
        <el-form-item
          style="width: 50%"
          label="姓名"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.contract_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          style="width: 50%"
          label="电话"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.contract_phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          style="width: 50%"
          label="城市"
          :label-width="formLabelWidth"
        >
          <el-cascader
            placeholder="选择地区"
            :options="options"
            filterable
            v-model="form.region"
          />
        </el-form-item>
        <el-form-item
          style="width: 50%"
          label="详细地址"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.address" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="添加新地址" :visible.sync="dialogFormVisible2">
      <el-form
        :model="form"
        style="
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        "
      >
        <el-form-item
          style="width: 50%"
          label="姓名"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form2.contract_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          style="width: 50%"
          label="电话"
          :label-width="formLabelWidth"
        >
          <el-input
            v-model="form2.contract_phone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          style="width: 50%"
          label="城市"
          :label-width="formLabelWidth"
        >
          <el-cascader
            placeholder="选择地区"
            :options="options"
            filterable
            v-model="form2.region"
          />
        </el-form-item>
        <el-form-item
          style="width: 50%"
          label="详细地址"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form2.address" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          style="width: 50%"
          label="默认地址"
          :label-width="formLabelWidth"
        >
          <el-radio v-model="defaultAddress" label="0">是</el-radio>
          <el-radio v-model="defaultAddress" label="5">否</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="addSite">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { http } from "../../../request/http";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
export default {
  name: "invoicing",
  data() {
    return {
      options: regionData,
      currenEdit: false,
      showAddress: false, //显示所有地址
      moreAddressList: [],
      currentAddress: {}, // 当前选择的地址
      form: {
        contract_name: "",
        contract_phone: "",
        region: "",
        address: "",
      },
      form2: {
        contract_name: "",
        contract_phone: "",
        region: "",
        address: "",
      },
      formLabelWidth: "120px",
      dialogFormVisible: false,
      dialogFormVisible2: false,
      defaultAddress: "5",
      cart: [
        {
          name: "OMNIS 双面羊毛呢子大衣女短款织衫女",
          price: "1999",
          spec: "Product 18",
          num: 1,
          img: "http://shop.super-gift.com.cn/image/cache/catalog/demo/product/product-9-100x100.png",
        },
        {
          name: "OMNIS 双面羊毛呢子大衣女短款织衫女",
          price: "1999",
          spec: "Product 18",
          num: 1,
          img: "http://shop.super-gift.com.cn/image/cache/catalog/demo/product/product-9-100x100.png",
        },
      ],
      detail: {},
    };
  },
  mounted() {
    this.getAddressList();
    this.queryDetail();
  },
  computed: {
    total() {
      let num = 0;
      this.cart.forEach((item) => {
        num += Number(item.price) + Number(item.num);
      });
      return num;
    },
  },
  methods: {
    getAddressList() {
      http("user.php", {
        fun: "myAddress",
      }).then((res) => {
        if (res.code == 0) {
          let list = res.data.sort((a, b) => {
            return new Date(b.time) - new Date(a.time);
          });
          this.currentAddress = list[0];
          this.$set(this.currentAddress, "checked", false);
          this.moreAddressList = list.filter((item, index) => {
            return index > 0;
          });
          this.moreAddressList.forEach((item) => {
            this.$set(item, "checked", false);
          });
        }
      });
    },

    addressClick(index) {
      let currentAddress = this.currentAddress;
      let i = index;
      this.currentAddress = this.moreAddressList[i];
      this.moreAddressList[i] = currentAddress;
      this.showAddress = false;
    },
    // 修改
    edit(data) {
      this.dialogFormVisible = true;
      let province = TextToCode[data.province].code;
      let city = TextToCode[data.province][data.city].code;
      let county = TextToCode[data.province][data.city][data.county].code;
      this.form = data;
      this.form.region = [province, city, county];
    },

    // 修改
    submit() {
      let {
        id,
        address,
        contract_name,
        contract_phone,
        province,
        city,
        county,
        region,
      } = this.form;
      province = CodeToText[region[0]];
      city = CodeToText[region[1]];
      county = CodeToText[region[2]];
      http("user.php", {
        fun: "editUserAddress",
        id,
        address,
        contract_name,
        contract_phone,
        province,
        city,
        county,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.dialogFormVisible = false;
          this.getAddressList();
        }
      });
    },
    // 添加地址
    addSite() {
      let form2 = this.form2;
      let province = CodeToText[form2.region[0]];
      let city = CodeToText[form2.region[1]];
      let county = CodeToText[form2.region[2]];
      let { defaultAddress } = this;
      http("user.php", {
        fun: "addUserAddress",
        contract_name: form2.contract_name, //姓名
        contract_phone: form2.contract_phone, //电话
        province, //省份
        city, //城市
        county, //区
        address: form2.address, //详细地址
        type: defaultAddress, //默认地址
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.dialogFormVisible2 = false;
          this.getAddressList();
        }
      });
    },

    // 查询商品详情
    queryDetail() {
      let id = this.$route.query.shopid;
      http("goods.php", {
        fun: "getGoodsEx",
        goods_dbids: id,
      }).then((res) => {
        if (res.code == 0) {
          this.detail = res.data[0];
        }
      });
    },
    // 确认订单
    submitOrder() {
      console.log(this.currentAddress);
      let { contract_name, province, city, county, address } = this.currentAddress
      let { id, name, img, price, origin } = this.detail;
      let goods = [
        {
          goods_dbid: id,
          goods_name: name,
          goods_num: this.$route.query.shopnum,
          discount: 100,
          img: img,
          price: price,
          marking_price: origin,
        },
      ];
      http("order.php", {
        fun: "createShopOrder",
        goods: JSON.stringify(goods),
        receive_name:contract_name,
        receive_address:`${province} ${city} ${county} ${address}`
      }).then(res=>{
          this.$message({
            type: 'success',
            message: '购买成功!'
          });
          setTimeout(()=>{
            this.$router.push('/order')
          },2000)
      })
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../scss/index.scss";
.invoicing {
  @include body;
  .cart-table {
    background: #fff;
    padding: 20px;

    .cart-table-header {
      display: flex;
      border-bottom: 1px solid #e5e5e5;
      padding: 0 10px;
      padding-bottom: 10px;
      font-size: 13px;

      div {
        &:nth-child(1) {
          width: 15%;
        }
        &:nth-child(2) {
          width: 46%;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 10%;
        }
        &:nth-child(6) {
          width: 10%;
        }
      }
    }
    .cart-table-content {
      display: flex;
      align-items: center;
      font-size: 13px;
      padding: 10px 0;
      border-bottom: 1px solid #e5e5e5;
      div {
        &:nth-child(1) {
          width: 15%;
          display: flex;
          img {
            width: 50px;
            height: 100%;
          }
        }
        &:nth-child(2) {
          width: 45%;
        }
        &:nth-child(3) {
          width: 10%;
        }
        &:nth-child(4) {
          width: 10%;
        }
        &:nth-child(5) {
          width: 9%;
        }
        &:nth-child(6) {
          width: 10%;
        }
      }
    }

    .table-price {
      display: flex;
      justify-content: flex-end;
      border-bottom: 1px solid #e5e5e5;
      padding: 10px 0;
      font-size: 13px;
      div {
        &:first-child {
          font-weight: bold;
        }
        &:last-child {
          width: 150px;
          text-align: right;
        }
      }
    }
  }
  .top {
    padding: 10px;
    background-color: #eeeeee;
    margin-bottom: 10px;
  }
  .distribution {
    border: 1px solid #d8d8d8;
    padding: 10px;
    margin-top: 20px;
    .add-address {
      background: #eee;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      font-size: 14px;
      div {
        &:first-child {
          font-weight: bold;
        }
        &:last-child {
          cursor: pointer;
          text-decoration: underline;
          color: #666;
          &:hover {
            color: red;
          }
        }
      }
    }
    .current-address {
      background: #fff3f3;
      margin-top: 10px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;

      .edit {
        display: flex;
        width: 100%;
        justify-content: space-between;
        div {
          &:last-child {
            color: #005ea7;
            cursor: pointer;
            &:hover {
              color: red;
            }
          }
        }
      }
    }

    .current-address2 {
      background: #fff;
      margin-top: 10px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        background: #fff3f3;
      }
      .edit {
        display: flex;
        width: 100%;
        justify-content: space-between;
        div {
          &:last-child {
            color: #005ea7;
            cursor: pointer;
            &:hover {
              color: red;
            }
          }
        }
      }
    }

    .more-address {
      font-size: 13px;
      margin-top: 10px;
      display: inline-block;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }
  }
  .top-content {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .content {
      width: 48%;
      border: 1px solid #d2d2d2;
      padding: 10px;
      box-sizing: border-box;
      .bottom {
        padding: 10px;
        background-color: #cbeacb;
        font-size: 14px;
        color: #398c39;
      }
    }
  }
  .cart-content,
  .message-content {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #d2d2d2;
  }
  .bottom {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
